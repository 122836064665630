"use strict";

(function () {
  function dependencies(window) {
    return {
      fetch: window.fetch,
      Request: window.Request,
      convertObjectFieldsInSnakeCase: window.convertObjectFieldsInSnakeCase
    };
  }
  function CommonStrategy(_ref, _ref2) {
    var httpMethod = _ref.httpMethod,
      _ref$serializeAsUri = _ref.serializeAsUri,
      serializeAsUri = _ref$serializeAsUri === void 0 ? false : _ref$serializeAsUri,
      _ref$serializeAsPaylo = _ref.serializeAsPayload,
      serializeAsPayload = _ref$serializeAsPaylo === void 0 ? false : _ref$serializeAsPaylo;
    var parseJson = _ref2.parseJson,
      verifyError = _ref2.verifyError,
      serializeUrlEncoded = _ref2.serializeUrlEncoded;
    return function executeRequest(uri, data, injection) {
      var _Object$assign = Object.assign({}, dependencies(window), injection),
        fetch = _Object$assign.fetch,
        Request = _Object$assign.Request,
        convertObjectFieldsInSnakeCase = _Object$assign.convertObjectFieldsInSnakeCase;
      var convertedData = convertObjectFieldsInSnakeCase(data);
      var uriParameteres = serializeAsUri ? serializeUrlEncoded(convertedData) : '';
      var gaeRequest = new Request("".concat(uri).concat(uriParameteres), {
        credentials: 'include',
        method: httpMethod,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: serializeAsPayload ? JSON.stringify(convertedData) : null
      });
      return fetch(gaeRequest).then(parseJson).then(verifyError);
    };
  }
  window.moduleExport({
    CommonStrategy: CommonStrategy
  }, typeof module !== 'undefined' && module);
})();